import React from 'react';

import { Layout } from '../components';

import './legal-returns-app.scss';

const LegalLimitedWarranty = () => (
  <Layout>
    <div className="wrapper">
      <div className="legal-returns-app">
        <h1 className="legal-returns-app__title">
          <strong>Safedome Card Limited Warranty</strong>
        </h1>
        <p>&nbsp;</p>
        <p>
          Maxwell Forest Inc. (“Maxwell Forest”) warrants to the recipient (“You”) that, subject to the terms of this
          Limited Warranty, for one year from the date of shipment to You, the Safedome card, under normal use, shall be
          free from defects in materials and workmanship and will materially perform in accordance with the user guide
          that Maxwell Forest distributes to you with the Safedome card (the “Limited Warranty Period”).
        </p>
        <p>
          Maxwell Forest does not warrant that the operation of the Safedome card will be error-free or uninterrupted.
          This Limited Warranty does not cover software, that is pre-installed or embedded on, or downloaded to, the
          Safedome card (“Embedded Software”), Maxwell Forest websites or online service, or the Safedome applications,
          which are provided on an ‘as is’ basis. Please refer to Maxwell Forest’s Master Terms of Service and End User
          License Agreement for more details.
        </p>

        <h6>1 EXCLUSIVE REMEDY FOR US RESIDENT CONSUMERS</h6>
        <p>
          For US resident consumers, as Maxwell Forest’s sole liability and your sole and exclusive remedy for any
          breach of the limited warranty set forth herein, Maxwell Forest will, at its option and expense, repair or
          replace your Safedome card that you return to Maxwell Forest during the warranty period in accordance with 2
          below that does not comply with such warranty, as confirmed by Maxwell Forest.{' '}
        </p>

        <h6>2 MAKING A WARRANTY CLAIM</h6>
        <p>
          To make a warranty claim you must write to Safedome Customer Service at customercare@safedome.com with your
          full name, email address and details of the defect to obtain a prepaid shipping label. You must return the
          defective card using this label in order to be issued with a replacement. Maxwell Forest will not issue
          refunds in exchange for a defective card. All returned Safedome cards for which a replacement has been issued
          will become the property of Maxwell Forest.
        </p>

        <h6>3 DURATION</h6>
        <p>
          The Limited Warranty extends for one year from the date your Safedome card is shipped to You. If Maxwell
          Forest provides a replacement device under this warranty, the replacement device will be covered under the
          remainder of the original Limited Warranty Period, if available.
        </p>

        <h6>4 EXCLUSIONS AND LIMITATIONS</h6>
        <p>
          The Limited Warranty does not apply to: (i) any products or services other than the physical components of the
          Safedome card; (ii) consumable parts, unless consumable part failure has occurred due to a mechanical or
          design defect; (iii) to cosmetic damage; (iii) to data, information, or software, including any such materials
          stored or embedded on the Safedome card. Without limiting the foregoing, Maxwell Forest shall not be
          responsible for, and hereby disclaims any liability for, Your or any third party’s software, firmware,
          information, or memory data contained in, stored on, or integrated with any Safedome card that is returned to
          Maxwell Forest for repair, whether under warranty or not.
        </p>
        <p>
          Maxwell Forest will have no obligation to the extent that any failure of a Safedome card to comply with the
          limited warranty set forth herein that results from or is otherwise attributable to: (i) negligence or misuse
          or abuse of the Safedome card; (ii) use of the Safedome card other than in accordance with Maxwell Forest’s
          published specifications or user manual; (iii) modifications, alterations or repairs to the Safedome card made
          by a party other than Maxwell Forest or a party authorized by Maxwell Forest; (iv) any failure by You or a
          third party to comply with environmental and storage requirements for the Safedome card specified by Maxwell
          Forest, including, without limitation, temperature or humidity ranges; (v) use of the Safedome card in
          combination with any third-party devices or products that have not been provided or recommended by Maxwell
          Forest; or (vi) loss or theft, accident, or exposure to liquids or extreme environmental conditions.
        </p>
        <p>
          Disassembling the Safedome card will void the Limited Warranty. The Safedome card contains no user-serviceable
          parts.
        </p>

        <h6>5 IMPLIED WARRANTIES</h6>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, MAXWELL FOREST EXPRESSLY DISCLAIMS ALL OTHER WARRANTIES AND CONDITIONS
          OF ANY KIND, WHETHER STATUTORY OR IMPLIED (INCLUDING, THE SALE OF GOODS ACT 1923 (NSW) AND OTHER SIMILAR
          AUSTRALIAN STATE BASED LEGISLATION AND THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE
          OF GOODS), REGARDING THE SAFEDOME CARD, INCLUDING ANY IMPLIED WARRANTY OF NON-INFRINGEMENT AND ANY WARRANTIES
          ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE, EXCEPT THAT ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR
          FITNESS FOR A PARTICULAR PURPOSE ARE LIMITED IN DURATION TO THE LIMITED WARRANTY PERIOD. NO ADVICE OR
          INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM MAXWELL FOREST OR ELSEWHERE, WILL CREATE ANY WARRANTY NOT
          EXPRESSLY STATED IN THIS LIMITED WARRANTY. Some states do not allow limitations on how long an implied
          warranty lasts, so the above limitation may not apply to you.
        </p>

        <h6>6 LIMITATION OF LIABILITY</h6>
        <h6 className="legal-returns-app__para-subtitle">6.1 General</h6>
        <p>
          UNDER NO CIRCUMSTANCES WILL MAXWELL FOREST BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR PUNITIVE LOSSES OR DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS, GOODWILL, USE,
          DATA OR OTHER INTANGIBLE LOSSES) OR COSTS OF PROCURING SUBSTITUTE PRODUCTS, ARISING OUT OF OR RELATING TO
          MAXWELL FOREST’S BREACH OF ITS LIMITED WARRANTY, YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE
          SAFEDOME CARD, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER LEGAL
          THEORY, WHETHER OR NOT MAXWELL FOREST WAS AWARE OR HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.
          MAXWELL FOREST AND YOU HAVE AGREED THAT THESE LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY
          SPECIFIED IN THIS LIMITED WARRANTY IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL MAXWELL
          FOREST’S LIABILITY TO YOU ARISING OUT OF, RELATING TO, OR IN CONNECTION WITH THIS LIMITED WARRANTY, FROM ALL
          CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY (INCLUDING NEGLIGENCE), EXCEED THE ACTUAL AMOUNT PAID TO
          MAXWELL FOREST BY YOU FOR THE SAFEDOME CARD THAT GIVES RISE TO THE CLAIM. Some jurisdictions do not allow the
          limitation or exclusion of liability for incidental or consequential damages, so the above limitation or
          exclusion may not apply to you.
        </p>

        <h6 className="legal-returns-app__para-subtitle">6.2 Australian Resident Consumers</h6>
        <p>
          Notwithstanding any other provision of these Terms, if you are an Australian resident Consumer (as defined in
          Schedule 2 of the Competition and Consumer Act 2010 (Cth) and the corresponding provisions of the Australian
          Consumer Law (New South Wales) (“Australian Consumer Law”)):
        </p>
        <p className="legal-returns-app__para--indent--1">
          1) all exclusions or limitations of liability under this Limited Warranty (including the Master Terms of
          Service and End User License Agreement) do not apply to any liability of Maxwell Forest to fail to comply with
          an applicable consumer guarantee under the Australian Consumer Law;
        </p>
        <p className="legal-returns-app__para--indent--1">
          (2) subject to paragraph (3) below, the liability of Maxwell Forest for any liability, loss, cost or damage,
          however caused (including by the negligence of Maxwell Forest), suffered or incurred by you because of a
          failure to comply with a consumer guarantee is limited for Maxwell Forest (at its election):
        </p>

        <p className="legal-returns-app__para--indent--2">
          (A) for services supplied under this Limited Warranty (including the Master Terms of Service and End User
          License Agreement), to the cost of:
          <ul>
            <li>resupplying the services; or</li>
            <li>paying the cost of having the services supplied again; or</li>
          </ul>
        </p>

        <p className="legal-returns-app__para--indent--2">
          (B) for goods supplied under this Limited Warranty (including the Master Terms of Service and End User License
          Agreement), to the cost of:
          <ul>
            <li>repairing or replacing the goods;</li>
            <li>supplying equivalent goods; or</li>
            <li>paying the cost to have the goods replaced or repaired.</li>
          </ul>
        </p>

        <p className="legal-returns-app__para--indent--1">
          (3) Section 6.2 (2) does not apply if it is not fair or reasonable for Maxwell Forest to rely on it for the
          purposes of section 64A of the Australian Consumer Law.
        </p>

        <h6>7 GOVERNING LAW</h6>
        <p>
          Each party agrees that this Limited Warranty will be subject to the venue designations and process set forth
          in the Master Terms of Service and, whether interpreted in a court of law or in arbitration, will be governed
          by the laws of California as they apply to agreements entered into and to be performed entirely within
          California by California residents regardless of your country of origin or where you access the Service, and
          without regard to conflict of law principles or the United Nations Convention for the International Sale of
          Goods.
        </p>

        <p>
          If any term of this Limited Warranty is deemed illegal or unenforceable, the legality or enforceability of the
          remaining terms shall not be affected or impaired.
        </p>
        <p>&nbsp;</p>
        <p>Last updated: September 4, 2015</p>
      </div>
    </div>
  </Layout>
);

export default LegalLimitedWarranty;
